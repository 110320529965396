<template>
  <div class="iss-main-index">
    <a-row :gutter="16" class="rowClass">
      <a-col :span="12">
        <div class="statistics-card" flex-attr="task">
          <div class="statistics-title">
            <div class="fontStyle">
              奖励金币
              <a-tooltip
                title="奖励金币"
                placement="top"
                color="#fe700b"
                overlayClassName="tips"
              >
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
            <div
              class="flex"
              style="justify-content: flex-start; align-items: baseline"
            >
              <div class="fs-32 mr-8">{{ inviterGoldNum }}</div>
              <div class="fs-16">个</div>
            </div>
          </div>
          <div class="imgBox">
            <img src="@/assets/images/marketing/left-icon.jpg" alt="" />
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="statistics-card" flex-attr="company">
          <div class="statistics-title">
            <div class="fontStyle">
              邀请好友
              <a-tooltip title="邀请好友" placement="top" color="#fe700b">
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
            <div
              class="flex"
              style="justify-content: flex-start; align-items: baseline"
            >
              <div class="fs-32 mr-8">{{ inviterUserNum }}</div>
              <div class="fs-16">人</div>
            </div>
          </div>
          <div class="imgBox">
            <img src="@/assets/images/marketing/right-icon.jpg" />
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="iss-main-grid" ref="mainRef">
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
          ref="gridRef"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ y: gridHeight }"
        >
          <template #avatar="{ record }">
            <div  @click="handleName(record)" class="cursor-p" >
              <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
              <a-avatar :size="30" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
          </template>
          <template #userName="{ record }">
            <div  @click="handleName(record)" class="activeColor cursor-p" v-if="record.userName">{{record.userName}}</div>
            <div  @click="handleName(record)" class="cursor-p" v-else>暂无</div>
          </template>
          <template #subscribeStatus="{ record }">
          <div  v-if="record.subscribeStatus === 1">订阅</div>
          <div v-if="record.subscribeStatus === 0">到期</div>
        </template>
        <template #status="{ record }">
          <div v-if="record.status === 1">注册</div>
          <div v-if="record.status === 0">注销</div>
        </template>
        <template #beginnerGuide="{ record }">
          <div v-if="record.beginnerGuide === 1">已激活</div>
          <div v-if="record.beginnerGuide === 0">未激活</div>
        </template>

          <template #goldNum="{ record }">
            <!--        数量样式处理   -->
            <div class="activeColor cursor-p" @click="handleOpen()">
              +
              <span>{{record.goldNum}}</span>
            </div>

          </template>

        </grid>
      </div>
    </div>
  </div>

  <a-modal
      v-model:visible="visible"
      title="邀请用户奖励金币数量明细"
      cancelText="取消"
      okText="确认"
      :width="600"
      centered
      :destroyOnClose="true"
      :footer="null"
      @ok="handleOk"
  >
    <div class="content">
      <div v-if="initValue.length === 0" class="noneList">暂无记录</div>
      <div v-else>
        <div v-for="item in initValue" :key="item.id" class="listClass">
          <div class="itemOne">
            <div class="name">{{ item.packageName }}</div>
            <div class="jinbi">{{ item.createTime }}</div>
          </div>
          <div class="itemTwo">
            <span class="ml-4" v-if="item.tradeType === 'add'">+</span>
            <span class="ml-4" v-if="item.tradeType === 'decrease'"
            >-</span
            >
            {{ item.goldNum }}
            <img src="@/assets/images/play/jinbi.png" class="jinbiPng" />
          </div>
        </div>
      </div>
    </div>


  </a-modal>


</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import {Avatar, Col as ACol, Row as ARow, Tooltip as ATooltip,} from 'ant-design-vue';
import { QuestionCircleOutlined, UserOutlined, } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
// import { downloadExcel } from '@/utils';
import Grid from '@/components/grid';
import marketingApi from '@/api/marketing.js';
import { useRouter, useRoute } from 'vue-router';
import {setAvatar} from "@/utils";
import userApi from "@/api/user";

export default {
  components: {
    ARow,
    ACol,
    ATooltip,
    QuestionCircleOutlined,
    SearchForm,
    Grid,
    AAvatar: Avatar,
    UserOutlined,

  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { userId } = route.query
    const state = reactive({
      search: {},
      inviterGoldNum: 0,
      inviterUserNum: 0,
      visible: false,
      initValue: [],
    });

    marketingApi.invitationCodeSendGold('',  userId).then((res) => {
      if(res) {
        state.inviterGoldNum = res.inviterGoldNum
        state.inviterUserNum = res.inviterUserNum
      }
    })

    watch(
        ()=>state.visible,
        (value)=>{
            if (value) {
              marketingApi.getOrderUserGoldNumDetail('', userId).then(res => {
                if (res) {
                  console.log('res打卡', res)
                  state.initValue = res
                }
              });

            }
        }
    )


    return {
      setAvatar,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 260,
      // url: marketingApi.getOrderUserPage,
      url: `${userApi.getOrderUserList}`+ `?userId=${userId}`,
      items: [
        {
          key: 'status',
          label: '用户状态',
          type: 'select',
          dataset: [
            { value: '1', label: '注册' },
            { value: '0', label: '注销' },
          ],
        },
        {
          key: 'subscribeStatus',
          label: '订阅状态',
          type: 'select',
          dataset: [
            { value: 1, label: '订阅' },
            { value: 0, label: '到期' },
          ],
        },
        {
          key: 'beginnerGuide',
          label: '激活状态',
          type: 'select',
          dataset: [
            { label: '已激活', value: 1 },
            { label: '未激活', value: 0 },
          ],
        },
        {
          key: 'date',
          timeKey: ['beginTime', 'endTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        { key: 'name', label: '昵称或联系方式' },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 80,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'userName',
          title: '昵称',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'userName' },
        },
        {
          dataIndex: 'mobile',
          title: '手机号',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'memberExpireTime',
          title: '订阅到期时间',
          width: 180,
          ellipsis: true,
        },
        { dataIndex: 'goldNum', title: '奖励金币数量', width: 180, ellipsis: true,
          slots: { customRender: 'goldNum' },
        },
        { dataIndex: 'status', title: '用户状态', width: 100, ellipsis: true,
          slots: { customRender: 'status'},
        },
        { dataIndex: 'subscribeStatus', title: '订阅状态', width: 100, ellipsis: true,
          slots: { customRender: 'subscribeStatus'},
        },
        { dataIndex: 'beginnerGuide', title: '激活状态', width: 100, ellipsis: true,
          slots: { customRender: 'beginnerGuide'},
        },
        { dataIndex: 'createTime', title: '邀请时间', width: 180, ellipsis: true },
      ],
      handleFnSearch: value => {
        state.search = value;
      },
      goDetail: ({ id }) => {
        router.push({
          name: 'userDetail',
          params: {
            id,
          },
        });
      },
      handleName: (record) => {
        return router.push(`/user/${record.id}`)
      },
      handleOpen: () => {
        state.visible = true
      },

    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-index {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.rowClass {
  margin: 16px 8px 0px !important;
}
.statistics-card {
  border-radius: 4px;
  padding: 20px 40px;
  background-color: #ffffff;
  .statistics-title {
    font-size: 16px;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    img {
      width: 100px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}
.butS {
  position: absolute;
  top: 66px;
  right: 30px;
  padding: 4px 16px;
}
.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}
</style>
